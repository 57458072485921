<template>
  <ag-charts-vue :options="options"></ag-charts-vue>
</template>

<script>
import { AgChartsVue } from 'ag-charts-vue';

export default {
  components: {
    'ag-charts-vue': AgChartsVue,
  },
  props: {
    data: { type: Array },
    title: { type: String },
    stacked: { type: Boolean, default: false },
    fillArray: { type: Array, default: null },
    showPercent: { type: Boolean, defaut: false },
    showLabel: { type: Boolean, default: true },
    labelOnlyPercent: { type: Boolean, default: false },
  },
  data() {
    return {
      options: null,
      percentageData: [],
    };
  },
  watch: {
    data(newData) {
      console.log(newData);
      const options = { ...this.options };
      this.percentageData = newData.map(obj => {
        const { week, xKey, totalCount, ...rest } = obj;
        const updatedValues = Object.fromEntries(
          Object.entries(rest).map(([key, value]) => [key, Math.round((value / totalCount) * 100)])
        );
        return { week, xKey, totalCount, ...updatedValues };
      });
      options.data = this.showPercent ? this.percentageData : newData;
      options.series = this.getSeriesData();
      this.options = options;
    },
  },
  created() {
    this.percentageData = this.data.map(obj => {
      const { week, xKey, totalCount, ...rest } = obj;
      const updatedValues = Object.fromEntries(
        Object.entries(rest).map(([key, value]) => [key, Math.round((value / totalCount) * 100)])
      );
      return { week, xKey, totalCount, ...updatedValues };
    });

    this.options = {
      autoSize: true,
      data: this.showPercent ? this.percentageData : this.data,
      legend: {
        orientation: 'horizontal',
        item: { maxWidth: 150 },
        spacing: 20,
      },
      theme: {
        baseTheme: 'ag-pastel',
        palette: {
          fills: this.fillArray
            ? this.fillArray
            : [
                '#c16068',
                '#a2bf8a',
                '#ebcc87',
                '#80a0c3',
                '#b58dae',
                '#85c0d1',
                '#1D4E89',
                '#393D3F',
                '#645986',
                '#D3D5D7',
                '#39603e',
                '#ee7d39',
                '#f3acae',
                '#e6d6c5',
                '#AD795E',
                '#807E7D',
              ],
          strokes: this.fillArray
            ? this.fillArray
            : [
                '#c16068',
                '#a2bf8a',
                '#ebcc87',
                '#80a0c3',
                '#b58dae',
                '#85c0d1',
                '#1D4E89',
                '#393D3F',
                '#645986',
                '#D3D5D7',
                '#39603e',
                '#ee7d39',
                '#f3acae',
                '#e6d6c5',
                '#AD795E',
                '#807E7D',
              ],
        },
        overrides: {
          series: {
            strokeWidth: 100,
          },
        },
      },
      title: {
        text: this.title,
        fontSize: 18,
        spacing: 25,
      },
      series: this.getSeriesData(),
      axes: [
        {
          type: 'category',
          position: 'bottom',
        },
        {
          type: 'number',
          position: 'left',
        },
      ],
    };
  },
  mounted() {},
  methods: {
    getSeriesData() {
      let keyCollection = [];
      this.data.map(item =>
        Object.keys(item).forEach(key => {
          if (!keyCollection.includes(key)) {
            keyCollection.push(key);
          }
        })
      );
      if (this.data.length > 0) {
        const yAxisMaxValue = Math.max(...this.data.map(o => o.yAxisMaxValue)) || null;
        const seriesConfig = keyCollection
          .filter(
            key =>
              key !== 'xKey' &&
              key !== 'week' &&
              key !== 'totalCount' &&
              key !== 'yAxisMaxValue' &&
              key !== 'weekStartDate'
          )
          .map(key => {
            return {
              type: 'column',
              xKey: 'xKey',
              yKey: key,
              yName: key,
              stacked: this.stacked ? true : false,
              tooltip: {
                renderer: params => {
                  if (!this.showPercent) {
                    const totalCount = params.datum?.totalCount || null;
                    const percentOfTotal = !isNaN(totalCount)
                      ? Math.round((params.yValue / totalCount) * 100)
                      : '-';
                    return (
                      '<div class="my-3 mx-2">' +
                      key +
                      ': ' +
                      params.yValue +
                      ' (' +
                      percentOfTotal +
                      '%)' +
                      '</div>'
                    );
                  } else {
                    const absoluteValue = this.data.find(weekData => weekData.xKey === params.xValue)[
                      params.yKey
                    ];
                    return `<div class="my-3 mx-2">${key}: ${absoluteValue} (${params.yValue}%)`;
                  }
                },
              },
              label: {
                formatter: ({ value }) => {
                  if (this.showLabel) {
                    const findItem = this.data.find(
                      item => Object.keys(item).includes(key) && item[key] === value
                    );
                    const percentOfTotal = !isNaN(findItem.totalCount)
                      ? Math.round((value / findItem.totalCount) * 100)
                      : '-';
                    // show value on the chart, when it has more than 5% height of they yAxis
                    if (!findItem.yAxisMaxValue) return value;
                    return value / findItem.yAxisMaxValue > 0.05
                      ? this.labelOnlyPercent
                        ? percentOfTotal + '%'
                        : value.toFixed() + ' (' + percentOfTotal + '%)'
                      : '';
                  } else return null;
                },
                color: this.showLabel ? 'white' : null,
              },
              highlightStyle: {
                item: {
                  fillOpacity: 0.1,
                  stroke: '#535455',
                  strokeWidth: 1,
                },
              },
            };
          });
        return seriesConfig;
      } else return [];
    },
  },
};
</script>
